import React from "react";
import AiSolutionWidget from "./components/AiSolutionWidget.js";
import DashboardHeader from "./components/DashboardHeader.js";
import Navbar from "./components/global/Navbar.js";
import HappyCustomersComponent from "./components/HappyCustomersComponent.js";
// import CaseStudiesComponent from "./components/mobile/CaseStudiesComponent.js";
import GetStartedComponent from "./components/GetStartedComponent.js";
import Footer from "./components/global/Footer.js";
import IntegrateIntoCRM from "./components/IntegrateIntoCRM.js";
import SolutionsComponent from "./components/SolutionsComponent.js";
// import AdvancingFutureComponent from "./components/mobile/AdvancingFutureComponent.js";
import AnthropodMagic from "./components/AnthropodMagic.js";
import FaqSection from "./components/FaqSection.js";
import SeamlessIntegration from "./components/SeamlessIntegration.js";

function MyComponentMobile() {
  return (
    <div className="flex flex-col items-center bg-white font-custom">
      <div className="self-stretch w-full min-h-[0px] max-md:max-w-full" />
      <div
        className="w-full pt-[100px]"
        id="home"
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <Navbar />
      </div>
      <div style={{ maxWidth: "2000px", marginLeft: "80px" }}>
        <DashboardHeader />
      </div>

      {/* <div className="w-full" style={{ maxWidth: "4000px", marginTop: "48px" }}>
        <HappyCustomersComponent />
      </div> */}
      <div className="w-full" id="features" style={{ maxWidth: "2000px" }}>
        <AiSolutionWidget />
      </div>
      {/* <div className="w-full">
          <CaseStudiesComponent />
        </div> */}
      <div className="w-full" style={{ maxWidth: "2000px" }}>
        <GetStartedComponent />
      </div>
      <div
        className="w-full"
        id="solutions"
        style={{ maxWidth: "2000px", maxHeight: "1500px" }}
      >
        <SolutionsComponent />
      </div>
      {/* <div className="w-full">
          <AdvancingFutureComponent/>
        </div> */}
      <div className="w-full" style={{ maxWidth: "2000px" }}>
        <IntegrateIntoCRM />
      </div>
      <div className="w-full" style={{ maxWidth: "2000px" }}>
        <AnthropodMagic />
      </div>
      <div className="w-full" style={{ maxWidth: "1750px" }}>
        <SeamlessIntegration />
      </div>
      <div className="w-full" id="faq" style={{ maxWidth: "2000px" }}>
        <FaqSection />
      </div>
      <div className="w-full" style={{ maxWidth: "4000px" }}>
        <Footer />
      </div>
    </div>
  );
}

export default MyComponentMobile;
